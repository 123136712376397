import { BookTwoTone } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VideoAskSchema from "../OnboardingScreen/videoAskIds.enum";

export default function PreTripPracticeCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: 400, mx: "auto", mt: 3, textAlign: "center" }}>
      <CardContent>
        <Box display="flex" justifyContent="center" mb={2}>
          <BookTwoTone color="primary" fontSize="large" />
        </Box>
        <Typography variant="h6" gutterBottom>
          {t("Pre-Trip Practice")}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {t(
            "Use this interactive exercise to practice your pre-trip inspection! We'll email you feedback after you finish."
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            navigate(`/onboarding/interview/${VideoAskSchema.PRE_TRIP.formId}`)
          }
        >
          {t("Practice")}
        </Button>
      </CardActions>
    </Card>
  );
}
