import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

type Props = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

export default function FullscreenBox({ children, sx }: Props) {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: (theme) => theme.zIndex.modal,
        overflowY: "auto",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
