export default function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file); // Converts the file to a Base64 Data URL
    reader.onload = () => {
      if (reader.result) {
        const base64String = reader.result as string;
        resolve(base64String); // Return the full Data URL (with the prefix)
      } else {
        reject(new Error("FileReader result is null or undefined"));
      }
    };
    reader.onerror = (error) => reject(error);
  });
}
