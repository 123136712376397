import { Typography, TypographyProps } from "@mui/material";
import { SimplifiedUserStatus } from "./simplifyStatus";

type Props = {
  status?: SimplifiedUserStatus;
} & TypographyProps;

function statusToActionItem(status: SimplifiedUserStatus): string {
  switch (status) {
    case "No Active Program":
      return "";
    case "Incomplete Application":
      return "The participant’s application is incomplete. We’re sending reminders and calling them, but we’d appreciate your help reaching them.";
    case "Inactive":
      return "The participant is inactive. We’ve tried contacting them via text and email and would appreciate your help reaching them.";
    case "Withdrawn":
      return "";
    case "Rejected":
      return "";
    case "Theory Training":
      return "";
    case "Skills Training":
      return "";
    case "Licensed":
      return "";
    case "Employed":
      return "";
    default:
      return "";
  }
}
export default function PartnerActionItemCell({ status, ...rest }: Props) {
  if (!status) return <Typography>-</Typography>;
  return <Typography {...rest}>{statusToActionItem(status)}</Typography>;
}
