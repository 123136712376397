import { Box, Pagination } from "@mui/material";
import { useState } from "react";
import TestimonialCard, { Testimonial } from "./TestimonialCard";

export const TESTIMONIALS: Testimonial[] = [
  {
    name: "Florene",
    uri: "https://f004.backblazeb2.com/file/emerge-courses/videos/testimonials/Florene.mp4",
    description:
      "Florene shares how her new CDL career is helping her pursue her dreams.",
    imgUri:
      "https://f004.backblazeb2.com/file/emerge-courses/testimonials/Florene+Little.jpg",
  },
  {
    name: "Trevon",
    uri: "https://f004.backblazeb2.com/file/emerge-courses/videos/testimonials/Trevon.mp4",
    description:
      "Trevon shares how the program helped him finally get off the streets.",
    imgUri:
      "https://f004.backblazeb2.com/file/emerge-courses/testimonials/Dyshawn.jpg",
  },
];

const TestimonialList = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" alignItems="center">
        <Box>
          <TestimonialCard {...TESTIMONIALS[currentIndex]} />
        </Box>
      </Box>

      {/* Dot Indicators using Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={TESTIMONIALS.length}
          page={currentIndex + 1}
          onChange={(event, value) => setCurrentIndex(value - 1)}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default TestimonialList;
