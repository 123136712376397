import { Avatar, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Header32 } from "src/components/Typography";
import { Partner } from "src/types/Partner";

type Props = {
  partner: Partner;
};

export default function PartnerRegisterWelcomeSection({ partner }: Props) {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Avatar
          src={partner.photoUrl}
          alt="Partner"
          sx={{
            height: 60,
            width: 60,
          }}
        />

        <Typography variant="body1" color="white">
          <Trans
            t={t}
            defaults="Invited By <bold>{{ partnerFirstName }} {{partnerLastName}}</bold>"
            values={{
              partnerFirstName: partner.firstName,
              partnerLastName: partner.lastName,
            }}
            components={{ bold: <b /> }}
          />
        </Typography>
      </Stack>
      <Header32 color="white">
        {t(
          "You're invited to apply for an Emerge Career training scholarship!"
        )}
      </Header32>
      <Typography variant="subtitle2" color="white">
        {t(
          "Looking for a real second chance? Our program is designed to help you get back on your feet and start a new career. Our average graduates makes $75K"
        )}
      </Typography>
    </Stack>
  );
}
