import { Partner } from "src/types/Partner";

export default function isPartnerGuard(obj: unknown): obj is Partner {
  if (typeof obj !== "object" || obj === null) return false;

  const staff = obj as Partner;

  return (
    typeof staff.uid === "string" &&
    (typeof staff.partnerOrganizationId === "undefined" ||
      typeof staff.partnerOrganizationId === "string") &&
    typeof staff.firstName === "string" &&
    typeof staff.lastName === "string" &&
    typeof staff.email === "string" &&
    typeof staff.phone === "string" &&
    typeof staff.invitationCode === "string" &&
    typeof staff.createdAt === "string"
  );
}
