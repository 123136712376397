import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Partner } from "src/types/Partner";

type Props = { partner: Partner };
export default function ReferralSteps({ partner }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const steps = [
    {
      number: "1",
      title: "Refer ",
      description:
        "Send your referral link to people that might benefit, and make sure they use it to create their account.",
    },
    {
      number: "2",
      title: "Follow their progress",
      description:
        "You can track when they create their account, finish their application, make progress towards training, and more.",
    },
    partner.paidBonusAmount
      ? {
          number: "3",
          title: "Get paid",
          description:
            "Earn your cash reward after your referral gets fully enrolled, two weeks into their skills training",
        }
      : {
          number: "3",
          title: "Receive updates",
          description:
            "Stay updated on your referral's progress and get notified when your referrals make progress towards the application.",
        },
  ];

  return (
    <Box sx={{ paddingY: 4 }}>
      <Typography
        variant="h5"
        fontWeight="bold"
        textAlign="center"
        marginBottom={6}
      >
        {t("Easy as 1, 2, 3")}
      </Typography>

      <Grid
        container
        spacing={isMobile ? 4 : 6}
        direction={isMobile ? "column" : "row"}
      >
        {steps.map((step) => (
          <Grid item xs={12} sm={4} key={step.number} textAlign="center">
            <Box
              sx={{
                width: 50,
                height: 50,
                borderRadius: "50%",
                border: "2px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "black",
              }}
            >
              {step.number}
            </Box>
            <Typography variant="h6" fontWeight="bold" mt={2}>
              {step.title}
            </Typography>
            <Typography variant="body1" color="textSecondary" mt={1}>
              {step.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
