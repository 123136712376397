import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useCallback, useState } from "react";
import { useAppContext } from "src/contexts/AppContext";
import useErrorHandler from "src/utils/useErrorHandler";

export default function usePartnerHandleLogin() {
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const { clients } = useAppContext();
  const auth = getAuth();
  const errorHandler = useErrorHandler();

  const sendSignInLink = httpsCallable(
    clients.functions,
    "handlePasswordlessSignIn"
  );

  const handleLogin = useCallback(
    async (email) => {
      setLoading(true);

      try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        if (!signInMethods.length) {
          throw new Error("Account does not exist");
        }

        await sendSignInLink({ email });
        window.localStorage.setItem("emailForSignIn", email); // Save email for later verification
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
        setFinished(true);
      }
    },
    [auth, sendSignInLink, errorHandler]
  );

  return {
    handleLogin,
    loading,
    finished,
  };
}
