import { UserSummary } from "../types/UserSummary";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function isUserSummaryGuard(data: unknown): data is UserSummary {
  if (data === null || typeof data !== "object") return false;

  const obj = data as UserSummary;

  return (
    typeof obj.createdAt === "string" &&
    (obj.lastLoggedIn === undefined || typeof obj.lastLoggedIn === "string") &&
    typeof obj.firstName === "string" &&
    typeof obj.lastName === "string" &&
    (obj.emergeCoachId === undefined ||
      typeof obj.emergeCoachId === "string") &&
    (obj.cohortId === undefined || typeof obj.cohortId === "string") &&
    (obj.organizationId === undefined ||
      typeof obj.organizationId === "string") &&
    (obj.applicationStatus === undefined ||
      typeof obj.applicationStatus === "string") &&
    (obj.applicationStatusDetails === undefined ||
      typeof obj.applicationStatusDetails === "string") &&
    (obj.enrollmentStatus === undefined ||
      typeof obj.enrollmentStatus === "string") &&
    (obj.enrollmentStatusDetails === undefined ||
      typeof obj.enrollmentStatusDetails === "string") &&
    (obj.employmentStatus === undefined ||
      typeof obj.employmentStatus === "string") &&
    (obj.employmentStatusDetails === undefined ||
      typeof obj.employmentStatusDetails === "string") &&
    typeof obj.videosStartedCount === "number" &&
    typeof obj.timelineEventCount === "number" &&
    (obj.averageScore === undefined || typeof obj.averageScore === "number") &&
    typeof obj.totalExamsCompleted === "number" &&
    typeof obj.attendanceCount === "number" &&
    (obj.theoryExamDate === undefined ||
      typeof obj.theoryExamDate === "string") &&
    (obj.theoryExamCompleted === undefined ||
      typeof obj.theoryExamCompleted === "string") &&
    (obj.medicalExamDate === undefined ||
      typeof obj.medicalExamDate === "string") &&
    (obj.medicalExamCompleted === undefined ||
      typeof obj.medicalExamCompleted === "string") &&
    (obj.skillsTrainingStartDate === undefined ||
      typeof obj.skillsTrainingStartDate === "string") &&
    (obj.skillsTrainingStartCompleted === undefined ||
      typeof obj.skillsTrainingStartCompleted === "string") &&
    (obj.skillsTrainingEndDate === undefined ||
      typeof obj.skillsTrainingEndDate === "string") &&
    (obj.skillsTrainingEndCompleted === undefined ||
      typeof obj.skillsTrainingEndCompleted === "string") &&
    (obj.licenseExamDate === undefined ||
      typeof obj.licenseExamDate === "string") &&
    (obj.licenseDateCompleted === undefined ||
      typeof obj.licenseDateCompleted === "string") &&
    typeof obj.generatedAt === "string"
  );
}
