import {
  AutorenewTwoTone,
  GroupTwoTone,
  LockTwoTone,
  PersonTwoTone,
  VisibilityTwoTone,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import TextInput from "src/components/Input/TextInput";
import ProfilePhotoInput from "src/components/ProfilePhotoInput";
import { Header20, ScreenTitle } from "src/components/Typography";
import isWebsite from "src/firebase/isWebsite";
import { Partner } from "src/types/Partner";
import USState from "src/types/states";
import isValidUSPhoneNumber from "src/utils/isValidUSPhoneNumber";

export type PartnerFormData = Omit<
  Partner,
  "createdAt" | "invitationCode" | "photoUrl" | "uid"
> & {
  photo?: File;
};

type Props = {
  defaultValues?: Partial<PartnerFormData>;
  onSubmit: (data: PartnerFormData) => void | Promise<void>;
};

export default function PartnerSignupForm({ defaultValues, onSubmit }: Props) {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
    watch,
  } = useForm<PartnerFormData>({
    mode: "onTouched",
    defaultValues: {
      weeklyUpdates: true,
      trainingHighlights: true,
      ...defaultValues,
    },
  });

  const [step, setStep] = useState(1);
  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const isLastStep = step === 3;
  const selectedType = watch("partnerType");

  const onSubmitForm = (data: PartnerFormData) => {
    if (isLastStep) {
      onSubmit(data);
    } else {
      nextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      {/* Step 1: Basic Details */}
      {step === 1 && (
        <Stack spacing={3} marginBottom={6}>
          <Box>
            <ScreenTitle>{t("Welcome to  our Partner Program!")}</ScreenTitle>
            <Typography marginTop={2}>
              {t(
                "Our program is designed to keep partners like you—whether corrections staff, government agencies, or community ambassadors—connected to participant progress in a secure, easy-to-follow way."
              )}
            </Typography>
          </Box>
          <TextInput
            control={control}
            name="firstName"
            label={t("First Name")}
            rules={{
              required: { value: true, message: t("First name is required") },
            }}
          />

          <TextInput
            control={control}
            name="lastName"
            label={t("Last Name")}
            rules={{
              required: { value: true, message: t("Last name is required") },
            }}
          />

          <TextInput
            control={control}
            name="email"
            label={t("Email")}
            rules={{
              required: { value: true, message: t("Email is required") },
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("Please enter a valid email address"),
              },
            }}
          />
          <TextInput
            control={control}
            name="phone"
            label={t("Phone number")}
            rules={{
              required: { value: true, message: t("Phone number is required") },
              validate: (value) =>
                isValidUSPhoneNumber(value) ||
                t("Please enter a valid phone number"),
            }}
          />

          <TextInput
            control={control}
            name="city"
            label={t("City")}
            rules={{
              required: { value: true, message: t("First name is required") },
            }}
          />
          <SelectInput
            name="state"
            label={t("State")}
            control={control}
            items={Object.entries(USState).map((entry) => ({
              value: entry[0],
              name: entry[1],
            }))}
            rules={{
              required: { value: true, message: t("This field is required") },
            }}
          />

          <Grid container justifyContent="space-between">
            <Button onClick={prevStep} disabled={step === 1}>
              {t("Back")}
            </Button>
            <Button variant="contained" onClick={nextStep} disabled={!isValid}>
              {t("Next")}
            </Button>
          </Grid>
        </Stack>
      )}

      {/* Step 2: Organization or Individual Selection */}
      {step === 2 && (
        <Stack spacing={3} marginBottom={6}>
          <Typography variant="h6">
            {t("Which option best describes you?")}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card
                variant="outlined"
                sx={(theme) => ({
                  cursor: "pointer",
                  border:
                    selectedType === "individual"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                })}
                onClick={() => setValue("partnerType", "individual")}
              >
                <CardContent>
                  <Box>
                    <PersonTwoTone />
                    <Header20>{t("I will refer on my own")}</Header20>
                    <Typography>
                      {t(
                        "Pick this if you’re referring people on your own, like a community advocate or brand ambassador."
                      )}
                    </Typography>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <LockTwoTone />
                        </ListItemIcon>
                        <ListItemText>
                          {t(
                            "Only you can see the progress of your referrals."
                          )}
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                variant="outlined"
                sx={(theme) => ({
                  cursor: "pointer",
                  border:
                    selectedType === "organization"
                      ? `2px solid ${theme.palette.primary.main}`
                      : "",
                })}
                onClick={() => setValue("partnerType", "organization")}
              >
                <CardContent>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box>
                      <GroupTwoTone />
                      <Header20>
                        {t("I will work with my team to refer people")}
                      </Header20>

                      <Typography>
                        {t(
                          "Pick this if you work with a group, such as a community organization or correctional partner."
                        )}
                      </Typography>
                      <List>
                        <ListItem>
                          <ListItemIcon>
                            <GroupTwoTone />
                          </ListItemIcon>
                          <ListItemText>
                            {t("Invite your team members to collaborate.")}
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <VisibilityTwoTone />
                          </ListItemIcon>
                          <ListItemText>
                            {t(
                              "Track referrals made by other people in your team."
                            )}
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <AutorenewTwoTone />
                          </ListItemIcon>
                          <ListItemText>
                            {t(
                              "Let your colleagues track the progress of your referrals."
                            )}
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {selectedType === "organization" && (
            <>
              <TextInput
                control={control}
                name="selfReportedOrganization.name"
                label={t("Organization Name")}
                rules={{
                  required: {
                    value: selectedType === "organization",
                    message: t("Organization name is required"),
                  },
                }}
              />

              <TextInput
                control={control}
                name="selfReportedOrganization.role"
                label={t("Your Title/Role")}
                rules={{
                  required: {
                    value: selectedType === "organization",
                    message: t("Title is required"),
                  },
                }}
              />

              <TextInput
                control={control}
                name="selfReportedOrganization.website"
                label={t("Website")}
                rules={{
                  required: {
                    value: selectedType === "organization",
                    message: t("Organization website is required"),
                  },
                  validate: isWebsite,
                }}
              />
            </>
          )}

          <Grid container justifyContent="space-between">
            <Button onClick={prevStep}>{t("Back")}</Button>
            <Button
              variant="contained"
              onClick={nextStep}
              disabled={
                !selectedType || (selectedType === "organization" && !isValid)
              }
            >
              {t("Next")}
            </Button>
          </Grid>
        </Stack>
      )}

      {/* Step 3: Photo Upload */}
      {step === 3 && (
        <Stack spacing={3} marginBottom={6}>
          <ScreenTitle>{t("Profile Photo")}</ScreenTitle>
          <Typography>
            {t(
              "Your profile photo helps us recognize you and build trust with referrals. Optional but recommended!"
            )}
          </Typography>

          <Box display="flex">
            <Box marginX="auto">
              <ProfilePhotoInput
                label={t("Your Photo")}
                name="photo"
                control={control}
              />
            </Box>
          </Box>

          <Alert severity="info">
            <Typography variant="body2">
              {t(
                "By creating an account, you agree to our terms and privacy policy. You’ll receive real-time email updates from Emerge Career about your referrals, with the option to unsubscribe anytime."
              )}
            </Typography>
          </Alert>

          <Grid container justifyContent="space-between">
            <Button onClick={prevStep}>{t("Back")}</Button>
            <SubmitButton loading={isSubmitting}>{t("Register")}</SubmitButton>
          </Grid>
        </Stack>
      )}
    </form>
  );
}
