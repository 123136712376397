import { httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "src/assets/WhiteFullLogo.svg";
import { useAppContext } from "src/contexts/AppContext";
import fileToBase64 from "src/firebase/fileToBase64";
import { useSnackbarContext } from "src/SnackbarProvider";
import useErrorHandler from "src/utils/useErrorHandler";
import RegisterLayout from "../SignupScreen/RegisterLayout";
import CheckLoginEmailCard from "./CheckLoginEmailCard";
import PartnerSignupForm, { PartnerFormData } from "./PartnerSignupForm";
import usePartnerHandleLogin from "./usePartnerHandleLogin";

export default function PartnerSignupScreen() {
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const snackbarContext = useSnackbarContext();
  const { clients } = useAppContext();
  const createPartner = httpsCallable(clients.functions, "createPartner");
  const { handleLogin, finished } = usePartnerHandleLogin();

  const onSubmit = async ({ email, photo, ...data }: PartnerFormData) => {
    const base64Photo = photo ? await fileToBase64(photo) : null;
    const sanitizedEmail = email.toLowerCase().trim();
    await createPartner({
      ...data,
      email: sanitizedEmail,
      photo: base64Photo,
    })
      .then(async () => {
        snackbarContext.alert(
          "success",
          t("Account created successfully! Log in to access your account.")
        );

        await handleLogin(sanitizedEmail);
      })
      .catch(errorHandler);
  };

  if (finished) {
    return (
      <RegisterLayout corner={<Logo width={300} />}>
        <CheckLoginEmailCard />
      </RegisterLayout>
    );
  }

  return (
    <RegisterLayout corner={<Logo width={300} />}>
      <PartnerSignupForm onSubmit={onSubmit} />
    </RegisterLayout>
  );
}
