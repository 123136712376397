import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

interface OpportunityCardProps {
  text: string;
  imageUrl: string;
}

const OpportunityCard: React.FC<OpportunityCardProps> = ({
  text,
  imageUrl,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={2}
      maxWidth={800}
      margin="auto"
    >
      {/* Text Section */}
      <Box
        sx={{
          flex: 1,
          pr: 2,
          transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
          transform: visible ? "translateX(0)" : "translateX(-20px)",
          opacity: visible ? 1 : 0,
        }}
      >
        <Typography variant="h4" fontWeight="bold" color="textPrimary">
          {text}
        </Typography>
      </Box>

      {/* Image Section */}
      <Box
        sx={{
          flex: 1,
          position: "relative",
          maxWidth: 400,
        }}
      >
        <Box
          component="img"
          src={imageUrl}
          alt="Opportunity"
          sx={{
            width: "100%",
            height: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

export default OpportunityCard;
