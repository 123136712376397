import {
  ContentCopy,
  Facebook,
  LinkedIn,
  Share as ShareIcon,
  Twitter,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Header32 } from "src/components/Typography";
import { useSnackbarContext } from "src/SnackbarProvider";
import { Partner } from "src/types/Partner";
import { DOMAIN_URL } from "src/utils/constants";
import useErrorHandler from "src/utils/useErrorHandler";

type Props = { partner: Partner };
export default function ReferralCard({ partner }: Props) {
  const referralLink = `${DOMAIN_URL}/register?invitationCode=${partner.invitationCode}`;
  const { t } = useTranslation();
  const { alert } = useSnackbarContext();
  const [open, setOpen] = useState(false);
  const errorHandler = useErrorHandler();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    alert("success", t("Referral link copied to clipboard!"));
  };

  const handleShare = (platform: string) => {
    let shareUrl = "";
    const encodedLink = encodeURIComponent(referralLink);
    const encodedText = encodeURIComponent(
      `I think you'd make a great fit for Emerge Career's CDL training program and wanted to invite you to sign up. Their graduates make on average $75K/year. Here's my invitation to learn more: ${referralLink}`
    );

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodedLink}&text=${encodedText}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}&title=${encodedText}`;
        break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };

  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Apply for this Emerge Career scholarship! `,
          text: `I think you'd make a great fit for Emerge Career's CDL training program and wanted to invite you to sign up. Their graduates make on average $75K/year. Here's my invitation to learn more: ${referralLink}`,
          url: referralLink,
        });
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            spacing={4}
            alignItems="center"
          >
            <Stack spacing={4}>
              <Box>
                <Header32>
                  {partner.paidBonusAmount
                    ? t(
                        "Earn {{ amount }} for every new shortlisted participant.",
                        {
                          amount: partner.paidBonusAmount,
                        }
                      )
                    : t("Invite individuals to Emerge!")}
                </Header32>
                {partner.paidBonusAmount ? (
                  <>
                    <Typography>
                      {t(
                        "Invite someone who can benefit from Emerge and earn when they finish the theory training and successfully start their hands-on training."
                      )}
                    </Typography>
                    <Link href="www.emergecareer.com/faq">
                      {t("Read the terms and program requirements")}
                    </Link>
                  </>
                ) : (
                  <Typography>
                    {t(
                      "Share your unique referral link with someone who can beneift from Emerge and receives updates on their training journey."
                    )}
                  </Typography>
                )}
              </Box>

              <TextField
                label="Your Referral Link"
                value={referralLink}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Box display="flex" alignItems="center">
                      <Tooltip title="Copy Link">
                        <IconButton onClick={handleCopyLink}>
                          <ContentCopy />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                }}
                variant="outlined"
                fullWidth
                margin="normal"
              />

              <Button
                size="large"
                variant="contained"
                onClick={handleOpenDialog}
              >
                {t("Share")}
              </Button>
            </Stack>
            <Avatar
              src="https://f004.backblazeb2.com/file/emerge-courses/assets/ReferralInvite.webp"
              sx={{
                width: { xs: 100, sm: 300 },
                height: { xs: 100, sm: 300 },
              }}
            />
          </Stack>
        </CardContent>
      </Card>

      {/* Dialog with sharing options */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{t("Share Referral Link")}</DialogTitle>
        <DialogContent>
          <List>
            <ListItem onClick={() => handleShare("facebook")}>
              <ListItemButton>
                <ListItemIcon>
                  <Facebook color="primary" />
                </ListItemIcon>
                <ListItemText primary={t("Share on Facebook")} />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => handleShare("twitter")}>
              <ListItemButton>
                <ListItemIcon>
                  <Twitter color="primary" />
                </ListItemIcon>
                <ListItemText primary={t("Share on Twitter")} />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={() => handleShare("linkedin")}>
              <ListItemButton>
                <ListItemIcon>
                  <LinkedIn color="primary" />
                </ListItemIcon>
                <ListItemText primary={t("Share on LinkedIn")} />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={handleCopyLink}>
              <ListItemButton>
                <ListItemIcon>
                  <ContentCopy />
                </ListItemIcon>
                <ListItemText primary={t("Copy Link")} />
              </ListItemButton>
            </ListItem>
            <ListItem onClick={handleNativeShare}>
              <ListItemButton>
                <ListItemIcon>
                  <ShareIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={t("More Options")} />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
