import { collection, getDocs, query, where } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Partner } from "src/types/Partner";
import isPartnerGuard from "src/utils/isPartnerGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchPartnerByInvitationCode(
  invitationCode: string,
  { db }: Clients
): Promise<Partner> {
  // Construct the Firestore query
  const q = query(
    collection(db, "partners"),
    where("invitationCode", "==", invitationCode)
  );

  // Execute the query
  const snapshot = await getDocs(q);

  // Handle empty result
  if (snapshot.empty) {
    throw new Error("Invalid invitation code");
  }

  // Clean and validate the partner document
  const partner = cleanFirestoreDoc(snapshot.docs[0]);

  if (!isPartnerGuard(partner)) {
    throw new Error("Malformed data");
  }

  return partner;
}
