import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoWhite } from "src/assets/WhiteFullLogo.svg";
import { Header32 } from "src/components/Typography";
import TestimonialList from "../DashboardPage/ProfileScreen/TestimonialList";
import RegisterLayout from "../SignupScreen/RegisterLayout";
import CheckLoginEmailCard from "./CheckLoginEmailCard";
import usePartnerHandleLogin from "./usePartnerHandleLogin";

const SidebarSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header32 color="white" marginBottom={2}>
        {t("Welcome Partners!")}
      </Header32>
      <Typography variant="subtitle1" color="white">
        {t(
          "Our partners are crucial in bringing more success stories like the ones below to life."
        )}
      </Typography>
      <Box marginTop={4}>
        <TestimonialList />
      </Box>
    </>
  );
};

export default function PartnerLoginScreen() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { handleLogin, loading, finished } = usePartnerHandleLogin();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  if (finished) {
    return (
      <RegisterLayout
        corner={<LogoWhite width={300} />}
        sidebar={<SidebarSection />}
      >
        <CheckLoginEmailCard email={email} />
      </RegisterLayout>
    );
  }

  return (
    <RegisterLayout
      corner={<LogoWhite width={300} />}
      sidebar={<SidebarSection />}
    >
      <Box
        display="flex"
        alignItems="center"
        margin="auto"
        justifyContent="center"
      >
        <Card sx={{ width: { md: 750 } }}>
          <CardHeader
            title={t("Sign in to your Emerge Career Partner account")}
          />
          <CardContent>
            {finished ? (
              <Stack direction="column" alignItems="center">
                <Box sx={{ width: 150 }}>
                  <img
                    src="https://f004.backblazeb2.com/file/emerge-courses/assets/EmailInvite.png"
                    alt="invite"
                    style={{ width: "100%" }}
                  />
                </Box>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  marginTop={3}
                  textAlign="center"
                >
                  {t(
                    "A sign-in link has been sent to your email. Please check your inbox (and spam) to complete the login."
                  )}
                </Typography>
              </Stack>
            ) : (
              <>
                <Typography variant="subtitle1" marginBottom={3}>
                  {t(
                    "Enter your email to receive a login link. You’ll be redirected back here to complete the login process."
                  )}
                </Typography>

                <TextField
                  label={t("Email Address")}
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={handleEmailChange}
                  type="email"
                  margin="normal"
                  required
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                  fullWidth
                  size="large"
                  disabled={!email || loading}
                >
                  {t("Send Login Link")}
                </Button>
              </>
            )}
            <Stack direction="row" alignItems="center" marginTop={4}>
              <Typography>{t("Don't have an account? ")}</Typography>
              <Button
                onClick={() => navigate("/partners/register")}
                size="large"
              >
                {t("Register now.")}
              </Button>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography>{t("Forgot your password? ")}</Typography>
              <Button onClick={() => navigate("/forgot-password")} size="large">
                {t("Reset it.")}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </RegisterLayout>
  );
}
