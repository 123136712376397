import { Avatar, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Organization } from "src/types";

type Props = {
  organization: Organization;
};

export default function OrganizationRegisterWelcomeSection({
  organization,
}: Props) {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={2}>
      <Avatar
        src={organization.logoImageUrl}
        alt="Organization logo"
        sx={{
          height: 60,
          width: 60,
        }}
      />

      <Typography color="white">
        <Trans
          t={t}
          defaults="We are proud to partner with <bold>{{ organization }}</bold> to offer opportunities for people looking for a new beginning"
          values={{ organization: organization.name }}
          components={{ bold: <b /> }}
        />
      </Typography>
    </Stack>
  );
}
