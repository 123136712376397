import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Placeholder from "../Placeholder.svg";
import withController from "./withController";

type Props = {
  label: string;
  value: File;
  onChange: (file: File | undefined) => void;
  error?: boolean;
};

export function ProfilePhotoInputBase({
  label,
  value,
  onChange,
  error,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    },
    maxFiles: 1,
    onDropAccepted: ([file]) => onChange(file),
  });

  const [previewUrl, setPreviewUrl] = useState<string>();
  useEffect(() => {
    if (!value) {
      setPreviewUrl(undefined);
      return;
    }

    const url = URL.createObjectURL(value);
    setPreviewUrl(url);

    // Cleanup function to revoke object URL
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(url);
  }, [value]);
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="overline" marginBottom={0.5}>
        {label}
      </Typography>
      <Box
        {...getRootProps()}
        sx={{
          cursor: "pointer",
          border: 1,
          borderStyle: "dashed",
          borderColor: error ? "error.main" : "rgba(0 0 0 / .23)",
          padding: 1,
          width: 250,
          height: 250,
          borderRadius: "50%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.03)",
        }}
      >
        <input {...getInputProps()} />
        {value ? (
          <Avatar
            src={previewUrl}
            alt="Profile Preview"
            sx={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <Stack spacing={2} textAlign="center" alignItems="center">
            <img src={Placeholder} alt="" width={48} height={48} />
            <Typography variant="body2" color="text.secondary">
              {t("Click to upload")}
            </Typography>
          </Stack>
        )}
      </Box>
      {value && (
        <Typography variant="body2" marginTop={1}>
          {t("Click or drag to replace photo")}
        </Typography>
      )}
      {error && (
        <Typography color="error" marginTop={1}>
          {t("Please upload a valid image.")}
        </Typography>
      )}
    </Box>
  );
}

export default withController(ProfilePhotoInputBase);
