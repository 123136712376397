import { format, getUnixTime } from "date-fns";
import {
  StudentReference,
  StudentReferenceType,
} from "src/types/StudentReference";
import { SchoolFormData } from "./pages/Admin/SuperAdminScreen/CreateSchoolForm";
import {
  CreateBackgroundFormData,
  EligibilityFormData,
} from "./pages/BackgroundSurveyScreen/BackgroundSurveyView";
import { PartnerFormData } from "./pages/PartnerScreen/PartnerSignupForm";
import { SignupFormData } from "./pages/SignupScreen/SignupScreen";
import { TrainingType } from "./types/Cohort";
import USState from "./types/states";
import Citizenship from "./utils/Citizenship.enum";
import Disability from "./utils/Disability.enum";
import EducationalAttainment from "./utils/EducationalAttainment";
import EmploymentStatus from "./utils/EmploymentStatus";
import Gender from "./utils/Gender.enum";
import MaritalStatus from "./utils/MaritalStatus.enum";
import Race from "./utils/Race.enum";
import ReferralSource from "./utils/ReferralSource.enum";
import YesNoSkip from "./utils/YesNoSkip";

const firstNames = [
  "John",
  "Jane",
  "Michael",
  "Emily",
  "Matthew",
  "Sarah",
  "David",
  "Laura",
  "James",
  "Jessica",
  "Robert",
  "Hannah",
  "Daniel",
  "Megan",
  "Paul",
  "Ashley",
  "Christopher",
  "Amanda",
  "Andrew",
  "Nicole",
];

const lastNames = [
  "Smith",
  "Johnson",
  "Williams",
  "Brown",
  "Jones",
  "Garcia",
  "Miller",
  "Davis",
  "Rodriguez",
  "Martinez",
  "Hernandez",
  "Lopez",
  "Gonzalez",
  "Wilson",
  "Anderson",
  "Thomas",
  "Taylor",
  "Moore",
  "Jackson",
  "Martin",
];

function getRandomElement<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

function getRandomName(): { firstName: string; lastName: string } {
  const firstName = getRandomElement(firstNames);
  const lastName = getRandomElement(lastNames);
  return { firstName, lastName };
}

const { firstName, lastName } = getRandomName();
export const SIGNUP_INFORMATION_SEED: SignupFormData = {
  email: `${firstName}${lastName}${format(
    new Date(),
    "yyyy/mm/d"
  )}@example.com`,
  password: "password1234",
  passwordConfirmation: "password1234",
  firstName,
  lastName,
  dateOfBirth: new Date("05/31/1998"),
  termsOfService: true,
  state: USState.CA,
  city: "California",
  phone: process.env.NODE_ENV === "development" ? "1111111111" : "",
  referralSource: Object.keys(ReferralSource)[0] as keyof typeof ReferralSource,
  doesNotHaveEmail: false,
};

export const BACKGROUND_SURVEY_SEED: CreateBackgroundFormData = {
  hasProficientEnglish: true,
  hasBeenIncarcerated: false,
  hasDriverLicense: true,
  hasHistoryChronicalIllness: true,
  hasBeenTerminated: true,
  hasSexOffense: false,
  hasViolentOffense: false,
  hasPendingCases: false,
  numberDependents: 0,
  numberDUIPast7Years: 0,
  numberDUIRecordLifetime: 0,
  householdIndividualsNumber: 0,
  numberPointsDriverRecord: 0,
  gender: Object.keys(Gender)[0] as keyof typeof Gender,
  race: [Object.keys(Race)[0]] as (keyof typeof Race)[],
  education: Object.keys(
    EducationalAttainment
  )[0] as keyof typeof EducationalAttainment,
  disability: YesNoSkip.Yes,
  citizenship: Object.keys(Citizenship)[0] as keyof typeof Citizenship,
  isHispanic: false,
  maritalStatus: MaritalStatus.Married,
  isIncarcerated: false,
  employmentStatus: Object.keys(
    EmploymentStatus
  )[2] as keyof typeof EmploymentStatus,
  benefits: [],
  driverLicenseReinstatementCost: 0,
  totalIncomeLastSixMonths: 0,
  canPassVisionTest: true,
  isTakingMedication: false,
  lgbtq: "Yes",
  isVeteran: false,
  isMilitarySpouse: false,
  ssn: "111111111",
  isProbationOrParole: false,
  disabilityType: Object.keys(Disability)[0] as keyof typeof Disability,
  zipcode: "11111",
  hasBeenProbationOrParole: false,
  isJusticeImpacted: false,
  hasDoneDiversionProgram: true,
  driverLicenseNumber: "S99988880",
  driverLicenseState: USState.MA,
};

export const ELIGIBILITY_FORM_SEED_DATA: Partial<EligibilityFormData> = {
  driverLicenseNumber: "S999888801",
  driverLicenseState: USState.MA,
};

export const SEED_CREATE_SCHOOL_DATA: SchoolFormData = {
  name: "Another School",
  description: "Very cool school",
  hours: "9am-5pm",
  address: "1234 Avenue St",
  state: USState.MA,
  city: "Springfield",
  email: "email@example.com",
  phone: "1111111111",
  type: TrainingType.CDL_A,
  trainingSchedules: "Monday-Friday, Weekends from 8am - 5pm",
  requirements: "At least 20 hours a week, Must have a valid driver license",
  hasFullTimeSchedule: true,
  hasPartTimeSchedule: true,
  hasWeekendSchedule: true,
  zipcode: "11111",
};

export const SEED_REFERENCE_DATA: Pick<
  StudentReference,
  "firstName" | "lastName" | "relationship" | "phone" | "email" | "type"
> = {
  email: "referece@example.com",
  firstName: "Reference",
  lastName: "Person",
  phone: "1111111111",
  relationship: "Friend",
  type: StudentReferenceType.Personal,
};

export const SEED_PARTNER: Omit<PartnerFormData, "uid" | "photo"> = {
  email: `partner-${getUnixTime(new Date())}@example.com`,
  firstName: "Reference",
  lastName: "Person",
  phone: "1111111111",
  title: "director",
  state: USState.AK,
  city: "Cali",
  partnerType: "individual",
  weeklyUpdates: true,
  trainingHighlights: true,
};
