export const TERMS_OF_SERVICE = "";
export const PRIVACY_POLICY = "";
// TODO: move this to an env var
export const DOMAIN_URL =
  process.env.NODE_ENV === "production"
    ? "https://www.app.emergecareer.com"
    : "http://localhost:3000";

export const CLP_ID = "55529691";
export const CONDITIONAL_ACCEPTANCE_LESSON_URL =
  "https://f004.backblazeb2.com/file/emerge-courses/CDL/videos/admissions/ConditionalAcceptanceVideo.mp4";
export const SUCCESS_PLAN_EXPLAINED_URL =
  "https://f004.backblazeb2.com/file/emerge-courses/CDL/videos/admissions/SuccessPlanExplained.mp4";

export const SSN_VIDEO_EXPLANATION =
  "https://f004.backblazeb2.com/file/emerge-courses/CDL/videos/admissions/WhySSN.mp4";

export const DRIVER_LICENSE_VIDEO_EXPLANATION =
  "https://f004.backblazeb2.com/file/emerge-courses/CDL/videos/admissions/WhyDriver_sLicense.mp4";

export const ACTION_CODE_REDIRECT_URL = `${DOMAIN_URL}/partners/dashboard`;
