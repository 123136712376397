import {
  BookTwoTone,
  ChatBubbleOutlineTwoTone,
  CheckCircleOutlineTwoTone,
  DirectionsCarTwoTone,
  EmojiEventsTwoTone,
  GroupTwoTone,
  HandshakeTwoTone,
  RocketTwoTone,
  RouteTwoTone,
  VideoCameraFrontTwoTone,
} from "@mui/icons-material";
import { t } from "i18next";
import { SliderExplanationStepProp } from "../SliderExplanationScreen";

export type VideoAskItem = {
  formId: string;
  label: string;
  shareId: string;
  sliderSteps?: SliderExplanationStepProp[];
  successMessage?: string;
};

const TEST_INTERVIEW = {
  formId: "ae487cb8-f308-4e78-8590-70f0fa3a654d",
  shareId: "fwi0cmap6",
  label: "Test Interview",
};

type CurriculumVideoAskKey = "PRE_TRIP" | "AIR_BRAKES";

type VideoAskKey =
  | "LEGACY_SUCCESS_PLAN"
  | "SUCCESS_PLAN"
  | "FIRST_INTERVIEW"
  | "RESUME_BUILDER"
  | CurriculumVideoAskKey;

const VideoAskSchema: Record<VideoAskKey, VideoAskItem> = {
  FIRST_INTERVIEW:
    process.env.NODE_ENV === "development"
      ? TEST_INTERVIEW
      : {
          formId: "29c98395-f254-4966-ad5e-242a5f5bc8c6",
          shareId: "forbuhp81",
          label: t("First Interview"),
        },
  LEGACY_SUCCESS_PLAN: {
    formId: "15b0f1cb-7587-498c-93a1-fb426d450fca",
    shareId: "faabhf1c5",
    label: t("Legacy Success Plan"),
  },
  SUCCESS_PLAN:
    process.env.NODE_ENV === "development"
      ? TEST_INTERVIEW
      : {
          formId: "c490643e-e778-47ac-9b79-6f464d9bc423",
          shareId: "f84idswrv",
          label: t("Success Plan"),
          sliderSteps: [
            {
              type: "component",
              icon: RouteTwoTone,
              title: "Setting You Up for Success",
              titleProps: { fontWeight: "bold" },
              body: "We’ll walk you through key things to consider so you’re fully prepared for the road ahead in your training",
            },
            {
              type: "component",
              icon: VideoCameraFrontTwoTone,
              title: "How This Works",
              titleProps: { fontWeight: "bold" },
              body: "You’ll record short video answers—this will only take a few minutes, and you can do it at your own pace.",
            },
            {
              type: "component",
              icon: HandshakeTwoTone,
              title: "Relax, We’ve Got Your Back",
              titleProps: { fontWeight: "bold" },
              body: "Be yourself—we’re here to support you every step of the way, no matter your situation.",
            },
          ],
        },
  RESUME_BUILDER: {
    formId: "ffcbc1bf-16ae-4b21-b2eb-0f04627e1133",
    shareId: "f5hg04ra3",
    label: "Resume & Cover Letter Builder",
    sliderSteps: [
      {
        type: "component",
        icon: RocketTwoTone,
        title: "Welcome to the Resume Builder",
        titleProps: { fontWeight: "bold" },
        body: "We’ll guide you through a simple, conversational process to build a resume & cover letter that represent your unique story.",
      },
      {
        type: "component",
        icon: ChatBubbleOutlineTwoTone,
        title: "Share Your Experiences",
        titleProps: { fontWeight: "bold" },
        body: "Answer a few questions about your work, education, and skills. Don't worry about being formal — our AI will craft a polished draft for you.",
      },
      {
        type: "component",
        icon: GroupTwoTone,
        title: "The More Details, the Better",
        titleProps: { fontWeight: "bold" },
        body: "Just talk to us like you’re chatting with a friend who wants to know what you do. The more details you share, the easier it’ll be for us to show off your skills and accomplishments!",
      },
      {
        type: "component",
        icon: CheckCircleOutlineTwoTone,
        title: "Review & Finalize",
        titleProps: { fontWeight: "bold" },
        body: "After your draft is created, the Emerge Success team will review and fine-tune it to ensure everything looks perfect. In 1-2 days, you’ll get the final version to review before it’s complete.",
      },
    ],
    successMessage:
      "You completed the section! We are generating your resume and cover letter. You will receive an email with the final version within 2 days.",
  },
  AIR_BRAKES: {
    formId: "6536b7a6-6be5-45ad-a333-7d4173766dfa",
    shareId: "f0ix0s2e3",
    label: "Air Brakes Practice",
    sliderSteps: [
      {
        type: "component",
        icon: DirectionsCarTwoTone,
        title: "Welcome to Air Brakes Practice",
        titleProps: { fontWeight: "bold" },
        body: "Mastering air brakes is key to passing your CDL test with a step-by-step, interactive guide. Let’s build your confidence before you hit the road",
      },
      {
        type: "component",
        icon: BookTwoTone,
        title: "Learn & Practice",
        titleProps: { fontWeight: "bold" },
        body: "Understand how the system works and practice the inspection process step by step. We’ll cover air pressure, leaks, and brake functionality.",
      },
      {
        type: "component",
        icon: EmojiEventsTwoTone,
        title: "Ready for Success",
        titleProps: { fontWeight: "bold" },
        body: "Once you’re done, we’ll email feedback and tips to help you improve and ace your CDL test!",
      },
    ],
    successMessage:
      "You completed the pre-trip section! You will receive an email with feedback within the next hour.",
  },
  PRE_TRIP: {
    formId: "a6e1c3e4-1a9d-4c7b-8e4e-7f0d4d5f5b0a",
    shareId: "feyx2a6wn",
    label: "Pre-Trip Practice",
    sliderSteps: [
      {
        type: "component",
        icon: DirectionsCarTwoTone,
        title: "Welcome to Pre-Trip Practice",
        titleProps: { fontWeight: "bold" },
        body: "We’re here to help you master your pre-trip inspection with a step-by-step, interactive guide. Let’s build your confidence before you hit the road!",
      },
      {
        type: "component",
        icon: BookTwoTone,
        title: "Learn the Basics",
        titleProps: { fontWeight: "bold" },
        body: "Get familiar with key parts of the vehicle and what to check. From lights to brakes, we’ll break it all down for you.",
      },

      {
        type: "component",
        icon: EmojiEventsTwoTone,
        title: "Ace Your Inspection",
        titleProps: { fontWeight: "bold" },
        body: "Once you’re done, we’ll email feedback and tips to help you improve and ace your CDL test!",
      },
    ],
    successMessage:
      "You completed the pre-trip section! You will receive an email with feedback within the next hour.",
  },
};

export default VideoAskSchema;
