import { Launch } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardProps,
  List,
  ListItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "src/components/ModalContainer";
import { UserAccount } from "src/types/User";
import ClientProfileLink from "./ClientProfileLink";

type Props = {
  students: Pick<UserAccount, "uid" | "firstName" | "lastName">[];
  title: string;
  metric: string | number;
  description?: string;
  disableNavigation?: boolean;
} & CardProps;

const StudentStatisticsCard = ({
  metric,
  students,
  title,
  description,
  disableNavigation,
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <Box>
      <Card {...rest}>
        <CardContent>
          <Stack spacing={1}>
            <Box>
              <Typography fontWeight="medium">{title}</Typography>
              {description && (
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
              )}
            </Box>
            <Typography variant="h4">{metric}</Typography>
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            sx={{ marginLeft: "auto" }}
            onClick={() => setIsOpen(true)}
            startIcon={<Launch />}
          >
            {t("See more")}
          </Button>
        </CardActions>
      </Card>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContainer>
          <Card title={`${title} (${students.length})`}>
            <CardContent
              sx={{
                overflowY: "auto",
                height: "60vh",
                maxHeight: "60vh",
              }}
            >
              <Typography>{title}</Typography>
              <Typography color="text.secondary" variant="body2">
                {students.length} {t("results found")}
              </Typography>
              <List>
                {students.map((student) =>
                  disableNavigation ? (
                    <ListItem key={student.uid}>
                      <Typography>
                        {student.firstName} {student.lastName}
                      </Typography>
                    </ListItem>
                  ) : (
                    <ListItem key={student.uid}>
                      <ClientProfileLink client={student} />
                    </ListItem>
                  )
                )}
              </List>
            </CardContent>
          </Card>
        </ModalContainer>
      </Modal>
    </Box>
  );
};

export default StudentStatisticsCard;
