import * as Sentry from "@sentry/react";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword as updateFirebasePassword,
  UserCredential,
} from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import posthog from "posthog-js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "src/firebase";
import FirestoreClient from "src/firebase/FirestoreClient";
import generateFirestoreTimestamps from "src/firebase/generateFirestoreTimestamps";
import { CreateOrganizationStaffData } from "src/pages/Admin/SuperAdminScreen/OrganizationStaffForm";
import { SignupFormData } from "src/pages/SignupScreen/SignupScreen";
import { Partner } from "src/types/Partner";
import { UserOnboardingStatus } from "src/types/User";
import { normalizeStr } from "src/utils";

type Session = FirestoreClient;

export type CreateUserInput = Omit<
  SignupFormData,
  "passwordConfirmation" | "termsOfService" | "dateOfBirth"
> &
  Required<Pick<SignupFormData, "state">> & {
    dateOfBirth: string; // whether the user is the admin of an organization
    organizationId?: string;
    cohortId?: string;
    referredBy?: Partner;
  };

type AuthContexTType = {
  session: Session | null;
  isLoggedIn: boolean;
  isLoading: boolean;
  // need to add isLoading definition that also takes into account users that do not have accounts
  login: (email: string, password: string) => Promise<UserCredential>;
  registerStudent: (data: CreateUserInput) => Promise<boolean>;
  registerOrganizationStaff: (
    data: CreateOrganizationStaffData
  ) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updatePassword: (password: string) => Promise<void> | null;
};

const AuthContext = React.createContext<AuthContexTType>({} as AuthContexTType);

export function useAuth() {
  return useContext(AuthContext);
}

export function useSession(): Session {
  const { session } = useAuth();

  if (!session) throw new Error("No auth session");

  return useMemo(() => session, [session]);
}

export function AuthProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  // load session
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (user) => {
        // NOTE: firebase event listeners are handy here because
        // they will catch sign-in, logout and register events on our behalf
        // will need to redo this later
        setLoading(true);
        if (user) {
          await FirestoreClient.create(user)
            .then((res) => {
              setSession(res);
              Sentry.setUser({ email: user.email || user.uid, uid: user.uid });
            })
            // eslint-disable-next-line no-alert
            .catch((err) => window.alert(err.message));
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        throw err;
      }
    );
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!session || !session.metadata) return;
    posthog.identify(session.metadata.uid);
  }, [session]);

  const value = useMemo(
    (): AuthContexTType => ({
      session,
      // whether the session can be retrieved
      isLoggedIn: !!session,
      isLoading,
      login: (email: string, password: string) =>
        signInWithEmailAndPassword(auth, normalizeStr(email), password),
      registerStudent: async ({ password, referredBy, ...input }) => {
        const { user } = await createUserWithEmailAndPassword(
          auth,
          input.email,
          password
        );
        await setDoc(doc(collection(db, `users`), user.uid), {
          ...input,
          onboardingStatus: UserOnboardingStatus.CREATED_ACCOUNT,
          ...generateFirestoreTimestamps(new Date(), "createdAt"),
          ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
          referredByPartnerId: referredBy?.uid,
        });
        return true;
      },
      registerOrganizationStaff: async ({
        password,
        passwordConfirmation,
        ...input
      }) => {
        if (password !== passwordConfirmation)
          throw new Error("Password must match");
        const { user } = await createUserWithEmailAndPassword(
          auth,
          input.email,
          password
        );

        await setDoc(doc(collection(db, `organizationStaff`), user.uid), {
          ...input,
          ...generateFirestoreTimestamps(new Date(), "createdAt"),
          ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
        });
      },
      logout: async () => {
        setSession(null);
        await auth.signOut();
        navigate("/");
      },
      resetPassword: (email: string) =>
        sendPasswordResetEmail(auth, normalizeStr(email)),
      updatePassword: (password: string) =>
        session &&
        updateFirebasePassword(session.credentials, normalizeStr(password)),
    }),
    [session, isLoading, navigate]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
