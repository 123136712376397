import { Box, Card, CardContent, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Header, PaddedLayout } from "src/components/Layout";
import PaddedLayoutContainer from "src/components/Layout/PaddedLayoutContainer";
import Template from "src/components/Layout/Template";
import { Header20 } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import createChatMessage from "src/firebase/createChatMessage";
import { useCurrentUser } from "src/SessionBoundary";
import { UserOnboardingStatus } from "src/types/User";
import { CLP_ID } from "src/utils/constants";
import useErrorHandler from "src/utils/useErrorHandler";
import CurrentTrainingWeeklyGoalCard from "../ClientScreen/CurrentTrainingWeeklyGoalCard";
import GradesTab from "../ClientScreen/GradesTab";
import StudentPlanTab from "../ClientScreen/StudentPlanTab/StudentPlanTab";
import AdminParticipantChat from "./AdminParticipantChatTab/AdminParticipantChatTab";
import { ChatMessageSenderType } from "./AdminParticipantChatTab/ChatMessage";
import AirBrakesPracticeCard from "./AirBrakesPracticeCard";
import DashboardCohortAnnouncements from "./DashboardCohortAnnouncements";
import DashboardTabs, { DashboardTab } from "./DashboardTabs";
import DashboardTrainingProgressCard from "./DashboardTrainingProgressCard";
import PreTripPracticeCard from "./PreTripPracticeCard";
import ResourceTab from "./ProfileScreen";
import PWASnackbar from "./PWASnackbar";
import RangeTab from "./RangeTab/RangeTab";
import SavedTab from "./SavedTab";
import TrainingProgressStepper from "./TrainingProgressStepper";

// ID of  CLP is currently hardcoded
export const DashboardScreen = () => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clients } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams({
    tab: "home",
  });
  const [tab, setTab] = useState<DashboardTab>(
    (searchParams.get("tab") as DashboardTab) || "home"
  );
  const errorHandler = useErrorHandler();

  const handleTabChange = (value: DashboardTab) => {
    setTab(value);
    setSearchParams((prev) => {
      prev.set("tab", value);
      return prev;
    });
  };

  useEffect(() => {
    if (
      user.onboardingStatus === UserOnboardingStatus.RANGE ||
      user.enrollmentStatus === "skills_training" ||
      user.enrollmentStatus === "completed_skills_training"
    )
      setTab("range");
  }, [user.enrollmentStatus, user.onboardingStatus]);

  const handleCoachResetMilestone = async () => {
    await createChatMessage(
      {
        participantId: user.uid,
        senderId: user.uid,
        senderType: ChatMessageSenderType.Participant,
        text: `I'd like to reset my study plan goals to get back on track!`,
      },
      clients
    )
      .then(() => setTab("chat"))
      .catch(errorHandler);
  };

  const navigateToCourse = () => navigate(`/courses/${CLP_ID}`);

  return (
    <Template>
      <PWASnackbar />
      <Header title="CDL Course" section={`Welcome back, ${user.firstName}!`}>
        <DashboardTabs onChange={handleTabChange} value={tab} />
      </Header>
      {user.cohortId && user.organizationId && (
        <PaddedLayout>
          <DashboardCohortAnnouncements
            cohort={{ uid: user.cohortId, organizationId: user.organizationId }}
          />
        </PaddedLayout>
      )}
      {tab === "home" && (
        <Box>
          <TrainingProgressStepper user={user} />

          <PaddedLayout>
            <PaddedLayoutContainer>
              <Box marginBottom={2}>
                <CurrentTrainingWeeklyGoalCard
                  onContactCoachToResetGoals={handleCoachResetMilestone}
                />
              </Box>

              <DashboardTrainingProgressCard
                client={user}
                handleClick={navigateToCourse}
              />

              <Box marginTop={8}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} md={6}>
                    {/* {process.env.NODE_ENV === "development" && (
                      <ResumeBuilderStatusCard user={user} />
                    )} */}
                    {(process.env.NODE_ENV === "development" ||
                      user.enrollmentStatus === "skills_training" ||
                      user.enrollmentStatus ===
                        "completed_skills_training") && (
                      <>
                        <Header20 my={2}>{t("Acing the CDL Exam")}</Header20>
                        <Stack>
                          <AirBrakesPracticeCard />
                          <PreTripPracticeCard />
                        </Stack>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Header20 my={2}>{t("Upcoming Milestones")}</Header20>
                    <StudentPlanTab
                      client={user}
                      hideButtons
                      onlyShowUpcomingMilestones
                      onReviewSuccessPlan={() => setTab("success_plan")}
                    />
                  </Grid>
                </Grid>
              </Box>
            </PaddedLayoutContainer>
          </PaddedLayout>
        </Box>
      )}
      {tab === "resources" && <ResourceTab />}
      {tab === "grades" && <GradesTab client={user} />}
      {tab === "saved" && <SavedTab />}
      {tab === "chat" && (
        <Box>
          <Box margin="auto" maxWidth={800}>
            <Card>
              <CardContent>
                <AdminParticipantChat client={user} />
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
      {tab === "success_plan" && (
        <PaddedLayout>
          <PaddedLayoutContainer>
            <StudentPlanTab client={user} />
          </PaddedLayoutContainer>
        </PaddedLayout>
      )}
      {tab === "range" && (
        <PaddedLayout>
          <RangeTab />
        </PaddedLayout>
      )}
    </Template>
  );
};

export default DashboardScreen;
