import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  email?: string;
};
export default function CheckLoginEmailCard({ email }: Props) {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader title={t("Check your email inbox")} />
      <CardContent>
        <Stack direction="column" alignItems="center">
          <Box sx={{ width: 150 }}>
            <img
              src="https://f004.backblazeb2.com/file/emerge-courses/assets/EmailInvite.png"
              alt="invite"
              style={{ width: "100%" }}
            />
          </Box>
          <Typography variant="subtitle1" marginTop={1} textAlign="center">
            {t("A unique sign-in link has been sent to {{ email }}.", {
              email: email || t("your email"),
            })}
          </Typography>
          <Typography variant="subtitle1" textAlign="center" marginTop={2}>
            {t(
              "Please check your inbox (and spam) to complete the login. You might have to give it a few minutes to arrive!"
            )}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
