import { UserAccount } from "src/types/User";
import { UserSummary } from "src/types/UserSummary";

export type SimplifiedUserStatus =
  | "Incomplete Application"
  | "No Active Program"
  | "Inactive"
  | "Withdrawn"
  | "Rejected"
  | "Theory Training"
  | "Skills Training"
  | "Licensed"
  | "Employed"
  | undefined;

export default function simplifyUserStatus(
  user: UserAccount | UserSummary
): SimplifiedUserStatus {
  if (!user.organizationId) return "No Active Program";
  if (!user.applicationStatus) return "Incomplete Application";
  if (user.applicationStatus === "inactive") return "Inactive";
  if (user.applicationStatus === "withdrawn") return "Withdrawn";
  if (user.applicationStatus === "rejected") return "Rejected";

  if (user.employmentStatus === "employed") {
    return "Employed";
  }

  if (user.enrollmentStatus === "licensed") {
    return "Licensed";
  }

  if (
    user.enrollmentStatus === "skills_training" ||
    user.enrollmentStatus === "completed_skills_training"
  ) {
    return "Skills Training";
  }

  if (
    user.applicationStatus === "conditionally_enrolled" ||
    user.applicationStatus === "enrolled" ||
    user.enrollmentStatus === "theory_training" ||
    user.enrollmentStatus === "completed_theory_training"
  ) {
    return "Theory Training";
  }

  return undefined;
}
