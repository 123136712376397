import {
  Button,
  ButtonProps,
  Card,
  CardContent,
  CardProps,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyBoxIcon } from "src/assets/EmptyBox.svg";
import { Header20 } from "./Typography";

type Props = {
  title?: string;
  description?: string;
  cta?: {
    onClick: () => void;
    label: string;
    variant?: ButtonProps["variant"];
  };
  secondaryCta?: {
    onClick: () => void;
    label: string;
    variant?: ButtonProps["variant"];
  };
} & CardProps;

export default function Empty({
  title,
  description,
  cta,
  secondaryCta,
  ...rest
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Card variant="outlined" {...rest}>
      <CardContent>
        <Stack width="100%" alignItems="center" gap={theme.spacing(2)}>
          <EmptyBoxIcon width={200} />
          {title && <Header20>{title}</Header20>}
          <Typography color="text.secondary">
            {description || t("No information to display yet.")}
          </Typography>
          <Stack display="flex" gap={1}>
            {cta && (
              <Button
                variant={cta.variant || "contained"}
                onClick={cta.onClick}
              >
                {cta.label}
              </Button>
            )}
            {secondaryCta && (
              <Button
                variant={secondaryCta.variant || "outlined"}
                onClick={secondaryCta.onClick}
              >
                {secondaryCta.label}
              </Button>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
