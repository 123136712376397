import { Box, BoxProps } from "@mui/material";
import React from "react";

type Props = BoxProps & {
  children?: React.ReactNode | React.ReactNode[];
};

export default function UpdatedPaddedLayout({
  children,

  ...rest
}: Props) {
  return (
    <Box
      {...rest}
      sx={[
        {
          paddingY: 2,
          marginX: { xs: 2, sm: 2, md: 16, lg: 24, xl: 32 },
          paddingX: { xs: 2, sm: 2, md: 16, lg: 24, xl: 32 },
        },
      ]}
    >
      {children}
    </Box>
  );
}
