import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import TextInput from "src/components/Input/TextInput";
import USState from "src/types/states";

export type LocationStepData = {
  state?: USState;
  city: string;
};

type Props = {
  defaultValues: LocationStepData;
  onBack: () => void;
  onSubmit: (data: LocationStepData) => void | Promise<void>;
};

export default function LocationSignupForm({
  defaultValues,
  onBack,
  onSubmit,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LocationStepData>({
    mode: "onTouched",
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSubmit({
          state: data.state,
          city: data.city,
        })
      )}
    >
      <Stack spacing={2} marginBottom={6}>
        <SelectInput
          name="state"
          label={t("State")}
          control={control}
          items={Object.entries(USState).map((entry) => ({
            value: entry[1],
            name: entry[0],
          }))}
          rules={{
            required: { value: true, message: t("State is required") },
          }}
        />
        <TextInput
          control={control}
          name="city"
          label={t("City")}
          rules={{
            required: { value: true, message: t("City is required") },
          }}
        />
      </Stack>

      <Grid container justifyContent="space-between">
        <Button onClick={onBack}>
          <ArrowBackIcon />
          &nbsp;
          {t("Back")}
        </Button>
        <SubmitButton loading={isSubmitting}>{t("Finish")}</SubmitButton>
      </Grid>
    </form>
  );
}
