import { AddOutlined, Edit, Rocket } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AdminOnlyAlert from "src/components/Button/AdminOnlyAlert";
import AdminOnlyButton from "src/components/Button/AdminOnlyButton";
import ModalContainer from "src/components/ModalContainer";
import { useAppContext } from "src/contexts/AppContext";
import createMilestoneTask from "src/firebase/createMilestoneTask";
import fetchMilestoneTasks from "src/firebase/fetchMilestoneTasks";
import updateMilestoneTaskCompletion from "src/firebase/updateMilestoneTaskCompletion";
import { MilestoneTask } from "src/types/MilestoneTask";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import isMilestoneOverdue from "src/utils/isMilestoneOverdue";
import replaceItem from "src/utils/replaceItem";
import useErrorHandler from "src/utils/useErrorHandler";
import MilestoneCardHeader from "./MilestoneCardHeader";
import MilestoneTaskForm, { MilestoneTaskFormData } from "./MilestoneTaskForm";
import MilestoneTaskItem from "./MilestoneTaskItem";

type Props = {
  milestone: StudentPlanMilestone;
  handleCompletion?: () => void;
  handleUndoCompletion?: () => void;
  handleEdit?: () => void;
  minified?: boolean;
};

export default function MilestoneCard({
  milestone,
  handleCompletion,
  handleUndoCompletion,
  handleEdit,
  minified,
}: Props) {
  const { t } = useTranslation();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();
  const [tasks, setTasks] = useState<MilestoneTask[]>();
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);

  // load tasks
  useEffect(() => {
    fetchMilestoneTasks(
      {
        milestoneId: milestone.uid,
        userId: milestone.userId,
      },
      clients
    )
      .then(setTasks)
      .catch(errorHandler);
  }, [clients, errorHandler, milestone.uid, milestone.userId]);

  const handleCheck = (oldTask: MilestoneTask) => {
    updateMilestoneTaskCompletion(
      {
        task: oldTask,
        completed: !oldTask.completedAt,
      },
      clients
    )
      .then((updatedTask) => {
        setTasks((prev) => {
          const updated = replaceItem(
            prev || [],
            oldTask,
            updatedTask,
            (item) => item.uid === oldTask.uid
          );

          return [...updated];
        });
      })
      .catch(errorHandler);
  };

  const handleTaskCreation = (data: MilestoneTaskFormData) => {
    createMilestoneTask({ ...data, milestone }, clients)
      .then((task) => setTasks((prev) => (prev ? [...prev, task] : [task])))
      .catch(errorHandler);

    setIsCreateTaskModalOpen(false);
  };

  if (minified) {
    return (
      <Card>
        <CardContent>
          <MilestoneCardHeader tasks={tasks} milestone={milestone} />
        </CardContent>
      </Card>
    );
  }

  if (milestone.completedAt) {
    return (
      <Card>
        <CardContent>
          <MilestoneCardHeader tasks={tasks} milestone={milestone} />

          <AdminOnlyAlert color="info" variant="outlined" sx={{ marginTop: 2 }}>
            {t(
              "The milestone was due on {{ dueDate }} and marked as completed on {{completionDate}}",
              {
                dueDate: format(new Date(milestone.date), "MMM d, yyyy"),
                completionDate: format(
                  new Date(milestone.completedAt),
                  "MMM d, yyyy"
                ),
              }
            )}
          </AdminOnlyAlert>
        </CardContent>
        <CardActions>
          <AdminOnlyButton onClick={handleUndoCompletion}>
            {t("Undo")}
          </AdminOnlyButton>
        </CardActions>
      </Card>
    );
  }

  // do something more aggressive -- like prompting user to really take action
  if (isMilestoneOverdue(milestone)) {
    return (
      <Card>
        <CardContent>
          <MilestoneCardHeader tasks={tasks} milestone={milestone} />
          <Typography variant="body2" color="text.secondary">
            {t("It was due on {{ dueDate }}", {
              dueDate: format(new Date(milestone.date), "MMM d, yyyy"),
            })}
          </Typography>
        </CardContent>
        <CardActions>
          {handleCompletion && (
            <Button
              color="success"
              onClick={handleCompletion}
              startIcon={<Rocket />}
            >
              {t("Complete")}
            </Button>
          )}
          {handleEdit && (
            <Button onClick={handleEdit} startIcon={<Edit />}>
              {t("Reschedule")}
            </Button>
          )}
        </CardActions>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <MilestoneCardHeader tasks={tasks} milestone={milestone} />

        {tasks && (
          <Stack spacing={1} mt={2}>
            {tasks.map((task) => (
              <Box key={task.uid}>
                <MilestoneTaskItem
                  task={task}
                  onCheck={() => handleCheck(task)}
                />
              </Box>
            ))}
          </Stack>
        )}
      </CardContent>
      <CardActions>
        {handleCompletion && (
          <Button
            color="success"
            onClick={handleCompletion}
            startIcon={<Rocket />}
          >
            {t("Complete")}
          </Button>
        )}
        {handleEdit && (
          <Button onClick={handleEdit} startIcon={<Edit />}>
            {t("Edit")}
          </Button>
        )}
        <Button
          onClick={() => setIsCreateTaskModalOpen(true)}
          startIcon={<AddOutlined />}
        >
          {t("Add Task")}
        </Button>
      </CardActions>
      <Modal
        open={isCreateTaskModalOpen}
        onClose={() => setIsCreateTaskModalOpen(false)}
      >
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                <MilestoneTaskForm onSubmit={handleTaskCreation} />
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>
    </Card>
  );
}
