import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Toolbar,
} from "@mui/material";
import { FirebaseError } from "firebase/app";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoIcon } from "src/assets/LogoIcon.svg";
import LogoutButton from "src/components/Button/LogoutButton";
import Empty from "src/components/Empty";
import FullscreenBox from "src/components/FullScreenBox";
import UpdatedPaddedLayout from "src/components/Layout/UpdatedPaddedLayout";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import fetchPartner from "src/firebase/fetchPartner";
import fetchReferralsByPartner from "src/firebase/fetchReferralsByPartner";
import { useSnackbarContext } from "src/SnackbarProvider";
import { Partner } from "src/types/Partner"; // Replace with the actual path to your Partner type
import { UserSummary } from "src/types/UserSummary";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import OpportunityCard from "./OpportunityCard";
import PartnerReferralTable from "./PartnerReferralTable";
import ReferralCard from "./ReferralCard";
import ReferralSteps from "./ReferralSteps";

export default function PartnerHomeScreen() {
  const [partner, setPartner] = useState<Partner | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const handleError = useErrorHandler();
  const auth = getAuth();
  const { clients } = useAppContext();
  const { alert } = useSnackbarContext();
  const errorHandler = useErrorHandler();
  const [referrals, setReferrals] = useState<UserSummary[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (!partner) return;
    fetchReferralsByPartner(partner, clients)
      .then(setReferrals)
      .catch(errorHandler);
  }, [clients, errorHandler, partner]);

  useEffect(() => {
    // Step 1: Check if the user arrived via email link
    const checkSignInLink = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Get stored email or prompt the user for it
        const email = window.localStorage.getItem("emailForSignIn");

        if (email) {
          try {
            // Step 2: Complete sign-in using the email link
            const result = await signInWithEmailLink(
              auth,
              email,
              window.location.href
            );

            // Step 3: Fetch partner data from Firestore
            const partner = await fetchPartner(result.user.uid, clients);
            setPartner(partner);
          } catch (error) {
            handleError(error);
            if (error instanceof FirebaseError) {
              alert("error", error.code);
              if (error.code === "auth/invalid-action-code") {
                alert(
                  "error",
                  "For your security, you will need to log in again."
                );
              }
              navigate("/partners/login");
            }
          } finally {
            setLoading(false);
          }
        } else {
          navigate("/partners/login");
        }
      } else {
        // User is not signed in or link is invalid
        navigate("/partners/login");
      }
    };

    checkSignInLink();
  }, [auth, handleError, navigate, location, clients, alert]);

  if (loading) return <AppSkeleton />;

  if (!partner)
    throw new Error(
      "We had a challenge loading your workspace. Please contact the Emerge Career staff to support you!"
    );

  return (
    <FullscreenBox>
      <AppBar position="static" color="transparent" elevation={1}>
        <Toolbar sx={{ backgroundColor: "white" }}>
          {/* Logo Section */}
          <Box flexGrow={1}>
            <LogoIcon width={30} />
          </Box>

          {/* User Info Section */}
          <Stack direction="row" alignItems="center" spacing={2}>
            {partner.photoUrl ? (
              <Avatar src={partner.photoUrl} alt={partner.firstName} />
            ) : (
              <Avatar />
            )}

            <LogoutButton />
          </Stack>
        </Toolbar>
        <Divider />
      </AppBar>

      <Stack
        spacing={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          marginY: 4,
        }}
      >
        <UpdatedPaddedLayout>
          <ScreenTitle marginBottom={2}>
            {t("Welcome back, {{ name }}", { name: partner.firstName })}
          </ScreenTitle>

          <Grid container marginBottom={4}>
            <Grid item xs={12} md={8}>
              <ReferralCard partner={partner} />
            </Grid>
          </Grid>

          {referrals && referrals.length > 0 ? (
            <PartnerReferralTable partner={partner} referrals={referrals} />
          ) : (
            <Empty
              title={t("No Referrals Yet")}
              variant="outlined"
              description={t(
                "Once individuals start signing up using your link, they will be displayed here."
              )}
            />
          )}
        </UpdatedPaddedLayout>
        <Box
          sx={{
            background:
              "linear-gradient(339deg, #F7F1EA 36.57%, #FDFCFA 72.98%, #FBFBFB 86.11%)",
          }}
        >
          <UpdatedPaddedLayout>
            <Box>
              <ReferralSteps partner={partner} />
            </Box>
          </UpdatedPaddedLayout>
        </Box>

        <Box>
          <OpportunityCard
            text={t(
              `Your referrals in {{ state }} can earn $75k/year once they graduate from the program`,
              { state: partner.state }
            )}
            imageUrl="https://images.unsplash.com/photo-1545315003-c5ad6226c272?q=80&w=2400&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />
        </Box>
      </Stack>
    </FullscreenBox>
  );
}
