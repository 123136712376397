import {
  Box,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery as measureScreenWidth,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PaddedLayout } from "src/components/Layout";
import { belowLargeTablet } from "src/components/responsiveHelpers";
import { Header32 } from "src/components/Typography";
import addStylesForDevices from "src/utils/addStylesForDevices";

type Props = {
  corner?: React.ReactNode;
  children: React.ReactNode;
  // TODO: change this to be a custom parameter
  sidebar?: React.ReactNode;
};

const cornerBaseStyles: SxProps<Theme> = {
  position: "absolute",
  top: (theme: Theme) => theme.spacing(4),
  display: "flex",
};

export default function RegisterLayout({ corner, sidebar, children }: Props) {
  const { t } = useTranslation();
  const isBelowLargeTablet = belowLargeTablet(measureScreenWidth);
  const gridContainerStyles = addStylesForDevices(
    isBelowLargeTablet,
    { background: "linear-gradient(135deg, #E44D26 0%, #FF9060 100%)" },
    { display: "none" }
  );
  const navigate = useNavigate();

  return (
    <Grid container sx={{ height: 1 }}>
      {corner && !isBelowLargeTablet && (
        <Grid
          container
          item
          md={4}
          sx={gridContainerStyles}
          minHeight={{ md: "100vh" }}
          maxHeight={1}
          overflow="hidden"
        >
          <Stack justifyContent="center">
            <Box
              sx={{
                ...cornerBaseStyles,
                left: (theme: Theme) => theme.spacing(2),
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              {corner}
            </Box>
            <PaddedLayout>
              {sidebar || (
                <>
                  <Header32 color="white" marginBottom={2}>
                    {t("Get Started with Emerge Career")}
                  </Header32>
                  <Typography variant="subtitle1" color="white">
                    {t(
                      "We are the job training program for individuals looking for a new beginning."
                    )}
                  </Typography>
                </>
              )}
            </PaddedLayout>
          </Stack>
        </Grid>
      )}

      <Grid item xs={11} sm={10} md={6} sx={{ marginX: "auto" }}>
        {corner && isBelowLargeTablet && (
          <Grid item xs={11} sx={cornerBaseStyles}>
            {corner}
          </Grid>
        )}
        <Grid sx={{ marginTop: { xs: 8, sm: 10 }, paddingY: 10 }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
