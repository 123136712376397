import { doc, getDoc } from "firebase/firestore";
import { Clients } from "src/contexts/AppContext";
import { Partner } from "src/types/Partner";
import isPartnerGuard from "src/utils/isPartnerGuard";
import cleanFirestoreDoc from "./cleanFirestoreDoc";

export default async function fetchPartner(
  partnerId: string,
  { db }: Clients
): Promise<Partner> {
  const res = await getDoc(doc(db, "partners", partnerId));

  if (!res.exists) throw new Error("Partner not found");

  const partner = cleanFirestoreDoc(res);

  if (!isPartnerGuard(partner)) throw new Error("Malformed data");

  return partner;
}
