import LockIcon from "@mui/icons-material/Lock";
import { Alert, AlertProps, AlertTitle } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useSessionAccountInformation } from "src/SessionBoundary";
import { UserType } from "src/types/User";

type AdminOnlyAlertProps = AlertProps & {
  children: React.ReactNode;
};

const AdminOnlyAlert: React.FC<AdminOnlyAlertProps> = ({
  children,
  ...rest
}) => {
  const account = useSessionAccountInformation();

  if (account.type !== UserType.Admin) {
    return <div />;
  }

  return (
    <Alert
      icon={<LockIcon />}
      title="This button is only visible to Emerge admins"
      {...rest}
    >
      <AlertTitle>{t("Internal Admin Note")}</AlertTitle>
      {children}
    </Alert>
  );
};

export default AdminOnlyAlert;
