import { InfoTwoTone } from "@mui/icons-material";
import { Chip, ChipProps, Tooltip, Typography } from "@mui/material";
import { blue, green, grey, orange, purple, red } from "@mui/material/colors";
import { SimplifiedUserStatus } from "./simplifyStatus";

type Props = {
  status?: SimplifiedUserStatus;
} & ChipProps;

function statusToColor(status: SimplifiedUserStatus) {
  switch (status) {
    case "No Active Program":
    case "Incomplete Application":
      return grey;
    case "Inactive":
      return red;
    case "Withdrawn":
      return orange;
    case "Rejected":
      return red;
    case "Theory Training":
      return blue;
    case "Skills Training":
      return purple;
    case "Licensed":
      return green;
    case "Employed":
      return green;
    default:
      return grey;
  }
}

function statusToDescription(status: SimplifiedUserStatus): string {
  switch (status) {
    case "No Active Program":
      return "The participant is in a location we do not offer programs yet.";
    case "Incomplete Application":
      return "The participant's application is incomplete.";
    case "Inactive":
      return "The participant is inactive and not currently engaged.";
    case "Withdrawn":
      return "The participant has withdrawn from the program.";
    case "Rejected":
      return "The participant's application was rejected due to disqualifiers.";
    case "Theory Training":
      return "The user is currently in the theory training phase.";
    case "Skills Training":
      return "The participant is currently in the skills training phase.";
    case "Licensed":
      return "The user participant obtained their license.";
    case "Employed":
      return "The participant is employed.";
    default:
      return "Status unknown.";
  }
}
export default function UserSimplifiedStatusChip({ status, ...rest }: Props) {
  if (!status) return <Typography>-</Typography>;
  return (
    <Tooltip title={statusToDescription(status)}>
      <Chip
        icon={<InfoTwoTone />}
        sx={{
          color: statusToColor(status)[900],
          borderColor: statusToColor(status)[100],
          backgroundColor: statusToColor(status)[100],
        }}
        label={status}
        {...rest}
      />
    </Tooltip>
  );
}
